html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

image {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a0909;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 425px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (min-width: 1000px) {
  .container {
    max-width: 1440px;
    padding: 0 100px;
  }
}

.Toastify__progress-bar-theme--dark {
  background-color: #ff267d !important;
}

.Toastify__toast-body {
  color: #ff267d !important;
}

@import url("./header.scss");
@import url("./table.scss");
@import url("./modal.scss");
@import url("./new.scss");
@import url("./download.scss");
