.download__button {
  display: flex;
  padding-left: 150px;
  padding-right: 120px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 503px;
  height: 51px;
  background: #ff267d;
  border: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  color: #1e1d1e;
}

.button__wrapper--download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 503px;
  margin: 20px auto 25px;
  gap: 20px;
}

.input__wrappers {
  width: 100%;
  border-top: 2px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.download__text {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  text-align: center;
  color: #ff267d;
}

.download__top--wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  gap: 8px;
}

.download__top--thumb {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.download__input {
  width: 244px;
  height: 71px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ff267d;
  background-color: transparent;
  border-radius: 15px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  color: #ff267d;
}

.download__input::placeholder {
  color: #c22262;
  text-align: center;
  border-bottom: 1px solid #c22262;
  border-radius: 15px;
  padding-bottom: 10px;
}

.download__or--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 21px;
}

.download__or {
  height: 37px;
  margin-top: 7px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: #c22262;
}

.download__or--line {
  width: 215px;
  border: 1px solid #c22262;
}

.download__middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

.download__middle--text {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  text-align: center;
  color: #ff267d;
}

.download__input--bottom {
  margin-top: 5px;
  width: 496px;
  height: 71px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ff267d;
  background-color: transparent;
  border-radius: 15px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  color: #ff267d;
}

.download__input--bottom::placeholder {
  color: #c22262;
  text-align: center;
  border-bottom: 1px solid #c22262;
  border-radius: 15px;
  padding-bottom: 10px;
}
