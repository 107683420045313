.new {
  padding-top: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;
}

.new__input {
  width: 460px;
  height: 71px;
  background-color: transparent;
  border: 1px solid #ff267d;
  border-radius: 15px;
  cursor: pointer;
  padding-left: 20px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  color: #ff267d;
}

.modal__password {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
}

.modal__password--btn {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 280px;
}

.new__input::placeholder {
  color: #c22262;
}

.new__input:focus {
  border: 1px solid #ff267d;
}

.new__btn {
  width: 460px;
  height: 100px;
  background: #ff267d;
  border: transparent;
  border-radius: 15px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: #1e1d1e;
}

.loadmore__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto 20px;
  width: 300px;
  height: 60px;
  background: #ff267d;
  border: transparent;
  border-radius: 15px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: #1e1d1e;
}

.new__text {
  font-family: "Rubik", sans-serif;
  font-size: 72px;
  font-weight: 700;
  line-height: 93.6px;
  text-align: center;
  color: #ffffff;
}
