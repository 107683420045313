.backdrop {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 350px;
  min-height: 305px;
  padding: 15px;
  border-radius: 15px;

  background: #1e1d1e;
  border: 1px solid #ff267d;
  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal__text {
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  color: #ffffff;
  word-break: break-all;
}

.modal__accent {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: #ffffff;
}
