.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 115px;
  background-color: #1e1d1e;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.header__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.header__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 65px;
  border: 1px solid #ff267d;
  border-radius: 10px;
}

.header__item--link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 65px;
  color: #ff267d;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
  border-radius: 10px;
}

.header__item:hover {
  background: #c22262;
}

.header__item:last-of-type:hover {
  background: transparent;
}

.header__item--link:hover {
  color: #ffffff;
}

.header__item--link.active {
  color: #161516;
  background-color: #ff267d;
}

.select__header--modal {
  position: absolute;
  bottom: -200px;
  z-index: 999;
  color: #fff;
  width: 150px;
  border-radius: 10px;
  background-color: #ff267d;
}

.select__header--item {
  height: 40px;
  text-align: center;
  border: 1px solid #1e1d1e;
}
