.table__container {
  width: 900px;
  margin: 11px auto 0;
}

.table__header {
  width: 950px;
  height: 51px;
  display: flex;
}

.table__header--item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 450px;
  height: 51px;
  background: #c22262;
  border: 1px solid #ff267d;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  text-align: center;
  color: #ffffff;
}

.modal__select {
  position: absolute;
  transform: translateY(100%);
  bottom: 0;
  width: 300px;
  border-radius: 10px;
  background: #292829;
  border: 1px solid #ff267d;
}

.modal__select--text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: center;
  color: #1e1d1e;
  background: #ff267d;
  border-radius: 10px 10px 0 0;
}

.modal__select--item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 23.4px;
  text-align: center;
  color: #ffffff;
  background: #c22262;
  border-bottom: 1px solid #ff267d;
}

.modal__select--item:last-of-type {
  border: 0;
  border-radius: 0px 0px 10px 10px;
}

.table__header--item:last-of-type:hover {
  background: #ff267d;
}

.table__header--left {
  border-radius: 10px 0 0 10px;
}

.table__header--right {
  border-radius: 0 10px 10px 0;
}

.table__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 950px;
  border-radius: 10px;
  border: 1px solid #ff267d;
}

.table__body:hover {
  background-color: #292829;
  cursor: pointer;
}

.table__body--item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 31.2px;
  text-align: center;
  color: #ffffff;
  border-right: 1px solid #ff267d;
}

.table__body--item:last-of-type {
  border-right: 0;
}

.modal2 {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 300px;
  height: 155px;
  padding: 16px;
  border-radius: 12px;

  background-color: #fff;

  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.sort__select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.sort__text {
  font-size: 18px;
  font-weight: 700;
}

.sort__input {
  width: 250px;
  height: 40px;
  font-size: 18px;
  border-radius: 12px;
  cursor: pointer;
}

.sort__btn {
  width: 250px;
  height: 40px;
  font-size: 18px;
  border-radius: 12px;
  color: #fff;
  border: 1px solid grey;
  background-color: teal;
  cursor: pointer;
}

.sort__btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}
